import axios from 'axios';
import { Link, navigate } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import GameContainer from '../../components/GameContainer';
import PageLayout from '../../components/PageLayout';
import { getRandomAvatar } from '../../helpers/avatarHelper';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchUserByCode, setUser } from '../../store/reducers/userSlice';

const DiscordAuth = ({ location }) => {
    const user = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();

    // React.useEffect(() => {
    //     dispatch(setIgnoreModal(true));

    //     return () => {
    //         dispatch(setIgnoreModal(false));
    //     };
    // }, []);

    React.useEffect(() => {
        if (user && user.isSignedIn) {
            navigate(`/`);
        }
    }, [user]);

    React.useEffect(() => {
        if (location && location.search) {
            const params = new URLSearchParams(location.search);
            const code = params.get('code');
            if (code) {
                dispatch(fetchUserByCode(code));
            } else {
                navigate(`/`);
            }
        }
    }, []);

    return (
        <PageLayout>
            <Link to='/'>
                <img src='/skissan.svg' alt='Skissan' />
            </Link>
            <h1>Signing in</h1>
            <Helmet>
                <title>Signing in - Skissan</title>
            </Helmet>
            <GameContainer textAlign='left' containerSize='large'>
                <div>
                    <h2>We are signing you in with Discord</h2>
                    <h3>Please wait.</h3>
                </div>
            </GameContainer>
            <div style={{ marginTop: '2rem' }}>
                <Link to='/games'>← Back to games list</Link>
            </div>
        </PageLayout>
    );
};

export default DiscordAuth;
